body {
  margin: 0;
  font-family: Nunito, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

*,
:after,
:before {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
}

button {
  &.npc {
    position: relative;
    width: 100%;
    padding: 0.75rem;
    border: 0px;
    border-radius: 5px;
    margin-top: 1rem;
    overflow: hidden;
  }

  .loader {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: inherit;
  }
}

.success {
  color: #41bea6;
}

.error {
  color: #d65db1;
}
