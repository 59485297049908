#calendar-app {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    position: relative;

    #day-wrapper {
        position: absolute;
        top: 1rem;
        // left: 2rem;
        right: 0.25rem;
        background-color: white;
        z-index: 1;

        display: flex;
        box-shadow: 0px 0px 7px -3px;
        border-radius: 10rem;
        overflow: hidden;
    }

    #calendar-view-wrapper {
        overflow: auto;
        position: relative;
        #calendar-view {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: auto;
            scroll-snap-type: y proximity;
            // position: relative;
        }

        .cal-row {
            height: 3rem;
            display: flex;
            align-items: center;
            // box-shadow: 0px 0px 0px 1px;
            border-bottom: 1px solid #e0ebe3;
            scroll-snap-align: center;
            // overflow-anchor: none;
            // color: gray;

            .cal-row-label {
                padding-inline: 0.5rem;
                font-weight: 600;

                .minute {
                    // color: rgb(150, 150, 150)
                }

                .sep {
                    width: 100%;
                    height: 1px;
                    border: 1px solid lightgray;
                }
            }

            &.active {
                // background-color: blue;
                // color: white;
                // font-weight: bold;
                // color: black;
            }

            .events {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 0.25rem 0.25rem;
                gap: 0.25rem;
                height: 100%;

                button {
                    height: 100%;
                    aspect-ratio: 1;
                    display: flex;
                    padding: 0.25rem;
                    border-radius: 4px;
                    background-color: white;
                    box-shadow: none;
                    border: 2px solid;

                    img {
                        background-size: cover;
                        width: 100%; /* or any custom size */
                        height: 100%;
                        object-fit: contain;
                    }

                    &.change {
                        background-color: rgba(97, 74, 191, 0.15);
                        color: #614abf;
                    }

                    &.feed {
                        background-color: rgba(0, 169, 139, 0.15);
                        color: #00a98b;
                    }

                    &.sleep {
                        background-color: rgb(0, 159, 250, 0.15);
                        color: #009ffa;
                    }
                }
            }
        }

        #active-zone {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            height: 3rem;
            // background-color: rgba(200, 0, 0, 0.3);
            // box-shadow: 0px 0px 0px 2px lightgray;
            width: 100%;
            z-index: 1;
            display: flex;
            pointer-events: none;

            > div {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 0.25rem 0.25rem;
                gap: 0.25rem;
                justify-content: end;

                button {
                    height: 100%;
                    aspect-ratio: 1;
                    display: flex;
                    padding: 0.25rem;
                    border-radius: 4px;
                    background-color: white;
                    box-shadow: none;
                    border: 2px solid;
                    pointer-events: all;

                    img {
                        background-size: cover;
                        width: 100%; /* or any custom size */
                        height: 100%;
                        object-fit: contain;
                    }
                    &.action {
                        &.feed {
                            color: #00a98b;
                        }

                        &.sleep {
                            color: #009ffa;
                        }

                        &.change {
                            color: #614abf;
                        }
                    }
                }
            }
        }
    }
}

.action-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    // font-size: inherit;

    .modal-content {
        background-color: white;
        padding: 16px;
        width: 100%;
        border-radius: 8px;
        position: relative;

        h2 {
            margin-top: 0;
        }

        h3 {
            font-size: 100%;
            margin-bottom: 0.5rem;
        }

        .close {
            position: absolute;
            top: 11px;
            right: 8px;
        }
    }
}

button {
    &.delete {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 0.75rem;
        background-color: transparent;
        border: 0px solid red;
        color: red;
        border-radius: 5px;
    }

    &.confirm {
        background-color: red;
        color: white;
        font-weight: bold;
    }
}

.top-right {
    position: absolute;
    top: 4px;
    right: 8px;
}

.tab-selectors {
    display: flex;
    justify-content: space-evenly;
    padding-block: 1rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    margin-top: auto;

    .tab-selector {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.selected {
            color: #d65db1;
        }

        > div {
            display: flex;
            justify-content: center;
        }
    }

    label {
        // display: none;
    }
}

.page {
    padding: 1rem;
}

.feedback-view {
    h2 {
        margin-bottom: 0.5rem;
    }

    .star-rating {
        .stars {
            display: flex;
        }

        .star {
            font-size: 150%;
            &.inactive {
                color: transparent;
                text-shadow: 0 0 0 rgb(229 230 233);
            }
        }
    }

    .status {
    }
}
